import { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CollapseProps } from 'antd';
import { filterStore } from '@modules/filter/adapter/outbound/stores/filter.store';
import { IMenuLink } from '@providers/footer/Footer.provider';
import { ModuleType } from '@shared/types/module.type';
import Contact from '../contact/Contact';
import Social from '../social/Social';
import { CollapseArrow, Container, CustomCollapse, SubTitle, Title } from './FooterMobile.style';
export interface Props {
  whatIEatMenu: IMenuLink[];
  productMenu: IMenuLink[];
  optionMenu: IMenuLink[];
  onClickTracker: (title: string) => void;
}
const FooterMobile: FC<Props> = ({
  whatIEatMenu,
  productMenu,
  optionMenu,
  onClickTracker
}: Props) => {
  const router = useRouter();
  const {
    clearAllSelectedCategoryItem
  } = filterStore(state => state);
  const renderMenu = (menus: IMenuLink[]) => {
    const isSearchPage = router.pathname === '/search';
    return menus.map((menu, index) => <Link href={menu.linkout} key={index} onClick={() => {
      onClickTracker(menu.text);
      isSearchPage && clearAllSelectedCategoryItem(ModuleType.Product);
    }}>
                <SubTitle>{menu.text}</SubTitle>
            </Link>);
  };
  const items: CollapseProps['items'] = [{
    key: 'what-i-eat',
    label: <Title>กินอะไรดี?</Title>,
    children: <>{renderMenu(whatIEatMenu)}</>
  }, {
    key: 'product',
    label: <Title>ผลิตภัณฑ์ซีพี</Title>,
    children: <>{renderMenu(productMenu)}</>
  }];
  return <Container>
            <CustomCollapse ghost items={items} expandIconPosition="end" expandIcon={({
      isActive
    }) => <CollapseArrow src="/icons/arrow-top-white.svg" alt="collapse arrow" $isActive={isActive} width={16} height={8} />} />
            {optionMenu.map(({
      linkout,
      text
    }, index) => <Title key={index}>
                    <Link href={linkout}>{text}</Link>
                </Title>)}
            <Contact onClickTracker={onClickTracker} />
            <Social onClickTracker={onClickTracker} />
        </Container>;
};
export default FooterMobile;