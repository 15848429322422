import { FC, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { authStore } from '@modules/auth/adapter/outbound/stores/auth.store';
import { userStore } from '@modules/user/adapter/outbound/stores/user.store';
import { getAuthCookiesName } from '@shared/helpers/getAuthCookiesName.helper';
const RefreshTokenLogic: FC = () => {
  const token = Cookies.get(getAuthCookiesName());
  const {
    userDetail,
    fetchUserDetail
  } = userStore(state => state);
  const {
    renewToken
  } = authStore(state => state);
  const [isInit, setIsInit] = useState(false);
  useEffect(() => {
    setIsInit(true);
  }, []);
  useEffect(() => {
    if (isInit) {
      if (token) {
        if (!userDetail) {
          fetchUserDetail();
        }
        const intervalId = setInterval(renewToken, 1000 * 60 * 2); // 2 minutes
        renewToken();
        return () => clearInterval(intervalId);
      }
    }
  }, [isInit, token]);
  return <></>;
};
export default RefreshTokenLogic;