import Head from 'next/head';
import { ISeo } from '@shared/models/seo.model';
interface Props {
  readonly seo: ISeo;
  readonly url: string;
}
export default function HeadElement({
  seo,
  url
}: Props) {
  return <Head>
            <title>{seo.title}</title>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="description" content={seo.description} />
            {/* <meta name="author" content="" /> */}
            <meta name="keywords" content={seo.keywords} />
            {/* <meta name="theme-color" content={theme.colors.primary[50]} /> */}
            <link rel="shortcut icon" href="/favicons/favicon.ico" />
            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={seo.title} />
            <meta property="og:locale" content="th_TH" />
            <meta property="og:description" content={seo.description} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="cpbrandsite" />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={seo.image} />
            <meta property="og:image:alt" content={seo.title} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            {/* Facebook App ID */}
            <meta property="fb:app_id" content={process.env.FACEBOOK_APP_ID} />
            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
        </Head>;
}