import Cookies from 'js-cookie';
import { IToken } from '@modules/auth/domain/model/auth.model';
import { GetUserProfileQueryDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { UserDetailEntityMapper } from '@modules/user/application/ports/userDetail.mapper';
import { getAuthCookiesName } from '@shared/helpers/getAuthCookiesName.helper';
import { IUserRepository } from '../../outbound/ports/user.repository.port';
export class UserRepositoryImpl implements IUserRepository {
  async getDetail(): IUserRepository.getDetailOutput {
    try {
      const cookies = Cookies.get(getAuthCookiesName());
      const tokens = cookies && JSON.parse(cookies) as IToken;
      const {
        data,
        errors
      } = await gqlQuery({
        query: GetUserProfileQueryDocument,
        headers: {
          authorization: tokens.jwt ? `Bearer ${tokens.jwt}` : ''
        }
      });
      if (errors) throw errors[0].message ?? 'Unknow Error';
      return UserDetailEntityMapper.toDomain(data.cdpGetUserProfile);
    } catch (error: any) {
      console.error('Error UserRepositoryImpl.getDetail', error);
      throw {
        data: error,
        message: error.message,
        statusCode: error.graphQLErrors[0].extensions.code
      };
    }
  }
}