import { FC } from 'react';
import Image from 'next/image';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import { CustomBackTop } from './BackTop.style';
const BackTop: FC = () => {
  const utag = useUtag();
  const handleOnClickTracker = () => {
    utag.link({
      tealium_event: 'back_to_top_click'
    });
  };
  return <CustomBackTop icon={<Image src="/icons/arrow-top.svg" alt="arrow-top" width={32} height={33} />} onClick={() => {
    handleOnClickTracker();
  }} />;
};
export default BackTop;