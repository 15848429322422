import Cookies from 'js-cookie';
import { IAuthRepository } from '@modules/auth/application/ports/auth.repository.port';
import { IToken } from '@modules/auth/domain/model/auth.model';
import { GetTokenMutationDocument, RenewTokenMutationDocument, RevokeTokenMutationDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlMutate } from '@modules/libs/graphql/gql-config';
import { getAuthCookiesName } from '@shared/helpers/getAuthCookiesName.helper';
import { TokenEntityMapper } from '../../outbound/ports/token.mapper';
export class AuthRepositoryImpl implements IAuthRepository {
  async getToken(authCode: string): IAuthRepository.getTokenResponse {
    try {
      const {
        data,
        errors
      } = await gqlMutate({
        mutation: GetTokenMutationDocument,
        variables: {
          data: {
            authCode
          }
        }
      });
      if (errors) throw errors[0].message ?? 'Unknow Error';
      return TokenEntityMapper.toDomain(data.cdpLogin);
    } catch (error: any) {
      console.error('Error AuthRepositoryImpl.getToken', error);
      throw {
        data: error,
        message: error.message,
        statusCode: error.graphQLErrors[0].extensions.code
      };
    }
  }
  async revokeToken(): IAuthRepository.revokeTokenResponse {
    try {
      const cookies = Cookies.get(getAuthCookiesName());
      const tokens = cookies && JSON.parse(cookies) as IToken;
      const {
        errors
      } = await gqlMutate({
        mutation: RevokeTokenMutationDocument,
        headers: {
          authorization: tokens.jwt ? `Bearer ${tokens.jwt}` : ''
        }
      });
      if (errors) throw errors[0].message ?? 'Unknow Error';
      return 'success';
    } catch (error: any) {
      console.error('Error AuthRepositoryImpl.revokeToken', error);
      throw {
        data: error,
        message: error.message,
        statusCode: error.graphQLErrors[0].extensions.code
      };
    }
  }
  async renewToken(): IAuthRepository.renewTokenResponse {
    try {
      const cookies = Cookies.get(getAuthCookiesName());
      const tokens = cookies && JSON.parse(cookies) as IToken;
      const {
        data,
        errors
      } = await gqlMutate({
        mutation: RenewTokenMutationDocument,
        headers: {
          authorization: tokens.jwt ? `Bearer ${tokens.jwt}` : ''
        }
      });
      if (errors) throw errors[0].message ?? 'Unknow Error';
      return TokenEntityMapper.toDomain(data.cdpRenewToken);
    } catch (error: any) {
      console.error('Error AuthRepositoryImpl.renewToken', error);
      throw {
        data: error,
        message: error.message,
        statusCode: error.graphQLErrors[0].extensions.code
      };
    }
  }
}