import { FC, ReactNode, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { authStore } from '@modules/auth/adapter/outbound/stores/auth.store';
import { userStore } from '@modules/user/adapter/outbound/stores/user.store';
import FooterProvider from '@providers/footer/Footer.provider';
import RefreshTokenLogic from '@components/auth/RefreshToken.logic';
import { BackTop } from '@components/backTop';
import { getAuthCookiesName } from '@shared/helpers/getAuthCookiesName.helper';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import { CustomLayout } from './Layout.style';
const HeaderProvider = dynamic(() => import('@providers/header/Header.provider'));
type Props = {
  children?: ReactNode;
};
const LayoutComponent: FC<Props> = ({
  children
}: Props) => {
  const router = useRouter();
  const utag = useUtag();
  const token = Cookies.get(getAuthCookiesName());
  const [isHeaderVisibleOnFirstInit, setIsHeaderVisibleOnFirstInit] = useState(false);
  const {
    getToken
  } = authStore(state => state);
  const {
    userDetail
  } = userStore(state => state);
  useEffect(() => {
    setIsHeaderVisibleOnFirstInit(true);
  }, []);
  useEffect(() => {
    handleRedirectPage();
  }, [router.pathname, userDetail]);
  useEffect(() => {
    if (isHeaderVisibleOnFirstInit && typeof router.query.authCode === 'string') {
      handleAuth();
    }
  }, [isHeaderVisibleOnFirstInit, router]);
  const handleAuth = () => {
    const {
      authCode,
      ...queryString
    } = router.query;
    try {
      getToken(authCode as string, utag);
    } catch (error) {
      console.error(error);
    } finally {
      router.push({
        pathname: router.pathname,
        query: queryString
      });
    }
  };
  const handleRedirectPage = () => {
    const isProfilePage = router.pathname.includes('/profile');
    if (isProfilePage && !token) router.push('/');
  };
  return <CustomLayout>
            <RefreshTokenLogic />
            {isHeaderVisibleOnFirstInit && <HeaderProvider />}
            {children}
            <FooterProvider />
            {/* <CookieBarProvider /> */}
            <BackTop />
        </CustomLayout>;
};
export default LayoutComponent;